<template>
  <div>
    <p class="font-bold text-2xl mb-6">Réinitialiser votre mot de passe</p>
      <ValidationObserver ref="form" tag="div"  class="">
        <form @submit.prevent="onSubmit">
            <ValidationProvider rules="required|email" name = "L'adresse email" v-slot="{ errors }">
                <div class="mb-6">
                    <m-input
                    :errors="errors[0]"
                    v-model="email"
                    name = "email"
                    label="E-mail"
                    >
                    <span slot="prepend"><svgicon name="mail" width="24" heigth="24"></svgicon></span>
                    </m-input>
                </div>
            </ValidationProvider>
            <div class="">
                <base-button type="submit" full color="primary" text="Créer un nouveau mot de passe" :loading="loading" :disabled="loading" />
            </div>
        </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      email: ''
    }
  },

  methods: {
    onSubmit () {
      this.loading = true
      this.$store.dispatch('auth/requestPasswordToken', { username: this.email })
        .then((response) => {
          if (this.username != this.email) {
            this.$store.commit('auth/setUsername', this.email)
          }
          this.$emit('request:passed', response)
        })
        .catch(error => {
          this.$emit('request:failed', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  computed: {
    ...mapGetters({
      username: 'auth/getUsername'
    })
  },

  mounted () {
    console.log('username --->', this.username);
    if (this.username) {
      this.email = this.username
    }
  }
}
</script>

<style>

</style>