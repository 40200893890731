<template>
  <div>
      <ValidationObserver ref="form" tag="div"  class="">
          <form @submit.prevent="onSubmit">
              <div class="mb-5">
                  <ValidationProvider name="Le mot de passe" rules="required|upCase|oneNumber|oneCharac|min:8|confirmed:confirmation" v-slot="{ errors }">
                      <m-input name = "pwd" :errors="errors[0]" v-model="password" :type="'password'" label="Mot de passe" autocomplete="new-password">
                          <!-- <div class="cursor-pointer" slot="append" @click="showPwd = !showPwd">
                              <svgicon name="eye" color="#5F738C" width="20" height="20"  v-if="!showPwd"></svgicon>
                              <svgicon name="eyeOff" color="#5F738C" width="20" height="20" v-else></svgicon>
                          </div> -->
                      </m-input>
                  </ValidationProvider>
              </div>
              <div  v-if="!validPassword">
                  <p class="text-xs mb-2">Votre mot de passe doit contenir au moins : </p>
                  <div class="grid grid-rows-2 grid-flow-col gap-1 mb-5 text-11">
                      <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.carac ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.carac ? '' : 'text-inkLight-active'">Un caractère spécial(#?*+/)</span></div>
                      <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.num ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.num ? '' : 'text-inkLight-active'">Un chiffre</span></div>
                      <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.upper ? '#27AE60' : '#5F738C'" width="4" height="4"></svgicon><span class="ml-1" :class="validMsg.upper ? '' : 'text-inkLight-active'">Une lettre majuscule</span></div>
                      <div class="flex flex-row items-center"><svgicon name="online" :color="validMsg.eightCarac ? '#27AE60' : '#5F738C'" width="4" height="4" ></svgicon><span class="ml-1" :class="validMsg.eightCarac ? '' : 'text-inkLight-active'">8 caractères minimum</span></div>
                  </div>
              </div>
              
              <div class="mb-5">
                  <ValidationProvider name="La confirmation du mot de Passe" rules="required" v-slot="{ errors }" vid="confirmation">
                      <m-input :errors="errors[0]" v-model="passwordConfirmation" name = "pwdConfirm" :type="'password'" label="Confirmer le mot de passe" autocomplete="new-password">
                          <!-- <div class="cursor-pointer" slot="append" @click="showConfirmPwd = !showConfirmPwd">
                              <svgicon name="eye" color="#5F738C" width="20" height="20"  v-if="!showConfirmPwd"></svgicon>
                              <svgicon name="eyeOff" color="#5F738C" width="20" height="20" v-else></svgicon>
                          </div> -->
                      </m-input>
                  </ValidationProvider>
              </div>
              <div class="">
                  <base-button type="submit" full color="primary" text="Valider" :loading="loading" :disabled="loading" />
              </div>
          </form>
      </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    code: {
      type: Number
    }
  },
  data() {
      return {
          loading: false,
          validMsg: {
              carac: false,
              num: false,
              upper: false,
              eightCarac: false
          },
          password: '',
          passwordConfirmation: '',
          validPassword: true,
      }
  },

  watch: {
    password(value){
        this.password = value;
        this.validatePassword(value);
        if (this.password.length >= 8 && this.validMsg.carac && this.validMsg.num && this.validMsg.upper && this.validMsg.eightCarac) {
            setTimeout(() => {
                this.validPassword = true
            }, 2000);
        }
        else if (this.password.length == 0) {
            this.validPassword = true
        }
        else {
            this.validPassword = false
        }
    }
  },

  methods: {
    validatePassword(value){
        let eigh = /.{8,}/;
        let numreq = /[0-9]+/;
        let upp = /[A-Z]+/;
        let car = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
        eigh.test(value) ? this.validMsg.eightCarac = true : this.validMsg.eightCarac = false;

        numreq.test(value) ? this.validMsg.num = true : this.validMsg.num = false;
        
        upp.test(value) ? this.validMsg.upper = true : this.validMsg.upper = false;
        
        car.test(value) ? this.validMsg.carac = true : this.validMsg.carac = false;
        
    },
    onSubmit(){
        this.$refs.form.validateWithInfo().then(validate => {
            let { isValid, errors } = validate;
            console.log(isValid, errors)
            if(isValid) {

                this.loading = true
                const data = {
                  password: this.password,
                  username: this.username
                }
                this.$store.dispatch('auth/changePassword', { token: this.code, data })
                  .then((response) => {
                    this.$emit('change-password:passed', response)
                  })
                  .catch(error => {
                    this.$emit('change-password:failed', error)
                  })
                  .finally(() => {
                    this.loading = false
                  })
            }
        })
        
    }
  },

  computed: {
    ...mapGetters({
      username: 'auth/getUsername'
    })
  }
}
</script>

<style scoped>

</style>