<template>
  <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/3 mx-auto">
          <div v-if="comp.name">
            <component :is="comp.name" v-on="comp.event" v-bind="comp.props" />
          </div>
        </div>
    </div>
</template>

<script>
import ChangePassword from './components/ChangePassword.vue'
import CheckPasswordResetToken from './components/CheckPasswordResetToken.vue'
import RequestPasswordReset from './components/RequestPasswordReset.vue'
export default {
  components: { 
    RequestPasswordReset,
    CheckPasswordResetToken,
    ChangePassword
  },

  data () {
    return {
      comp: {
        name: 'RequestPasswordReset',
        event: {
          'request:passed': this.onRequestPasswordSuccess
        },
        props:{}
      }
    }
  },

  methods: {
    onRequestPasswordSuccess () {
      this.comp = {
        name: 'CheckPasswordResetToken',
        event: {
          'check:passed': this.onCheckResetCodeSuccess
        },
        props:{}
      }
    },

    onCheckResetCodeSuccess (response) {
      this.comp = {
        name: 'ChangePassword',
        event: {
          'change-password:passed': this.onChangePasswordSucess
        },
        props:{
          code: response.code
        }
      }
    },

    onChangePasswordSucess () {
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style>
</style>
